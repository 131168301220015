<template>
  <landing-card
    :title="title"
    icon-type="el-icon-fa-coffee"
    :description="description"
    :nextLink="getNextPath"
    v-if="!isLoading"
    :checkRTL="checkRTL"
  >
    <div>
      <radialTimer
        @stop="stop"
        :duration="getBreakLimit || 600"
        :message="message"
        :inMinutes="true"
      />
    </div>
  </landing-card>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';
import { i18nWL } from '@/i18n';
import radialTimer from '../../components/radialTimer';
import { routerAsync } from '@/app-module';
export default {
  name: 'take-a-break',
  props: ['examId', 'qNum'],
  data: () => {
    return {
      lang: ''
    }
  },
  async created() {
    this.lang = this.currentExam ? this.currentExam.examLanguage.code : 'en';
    try {
      await this.fetchOnlyCurrentExam(this.examId);
    } catch (error) {
      //todo goster kullaniciaya?
      // console.log(
      //   'Exam and Question data retrieve exception: ',
      //   error.message,
      // );
    }
  },
  async mounted() {
    this.setFinishBreak();
    window.onpopstate = () => {
      window.history.forward();
    };
  },
  components: {
    radialTimer,
  },
  computed: {
    ...mapGetters({
      currentExam: 'onlineExam/main/currentExam',
      checkRTL: 'onlineExam/main/checkRTL',
      isLoading: 'onlineExam/main/isLoading',
    }),
    getNextPath() {
      return `/online-exam/${this.examId}/${this.qNum}`;
    },
    getBreakLimit() {
      let breakLimit = 0;
      try {
        breakLimit = this.currentExam.structure
          .structureData.breaks[0].breakLimit;
      } catch (e) {
        // todo kullaniciyi bilgilendir
        // console.log("break limit atama",e);
      }
      return breakLimit * 60;
    },
    description() {
      return i18nWL('entities.takeabreak.description', this.lang).replace(
          '${time}',
          this.getBreakLimit / 60);
    },
     title() {
      return i18nWL('entities.takeabreak.title', this.lang).replace(
          '${time}',
          this.getBreakLimit / 60);
    },
    message() {
      return i18nWL('entities.takeabreak.message', this.lang);
    },
  },

  methods: {
    ...mapActions({
      fetchOnlyCurrentExam:
        'onlineExam/main/fetchOnlyCurrentExam',
    }),
    ...mapMutations({
      setFinishBreak: 'onlineExam/main/SET_FINISH_BREAK',
    }),
    stop() {
      routerAsync().push(this.getNextPath);
    },
  },
};
</script>

<style lang="stylus"></style>
